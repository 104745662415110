import howardLogo from './howard.png';
import nkuLogo from './nku.png';
import utaLogo from './uta.png';
import untLogo from './unt.png';
import ecuLogo from './ecu.png';
import fiskLogo from './fisk.jpeg';

const logos = [
		howardLogo,
		nkuLogo,
		utaLogo,
		untLogo,
		ecuLogo,
		fiskLogo
	];
export default logos;